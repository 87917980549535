import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Users } from '../_models/users';
import { UserService } from '../services/_objects/user.service';
import { GlobalVarService } from '../services/global-var.service';
import {ConfigMiniSidebar} from '../../shared/sidebar/mini-sidebar/config-mini-Sidebar.service'

@Injectable({ providedIn: "root"})
export class AuthService { 

  token: string;
  authUser: Users;

  constructor(private $userService: UserService, private config:ConfigMiniSidebar) {}

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  login(email: string, password: string) {
    //your code for checking credentials and getting tokens for for signing in user
    return this.$userService.login(email, password);
  }

  logout() {   
    this.token = null;
    this.authUser = null;
    this.$userService.logout();
  }

  getToken() {    
    return this.token;
  }

  isAuthenticated() {
    // here you can check if user is authenticated or not through his token 
    // return true; 

    if(localStorage.getItem(GlobalVarService.KEY_USER_INFO)){
      this.authUser = JSON.parse(localStorage.getItem(GlobalVarService.KEY_USER_INFO));
      this.token = this.authUser.api_token;
      
    }
    

    return (this.token != null && this.token != "");
  }
}
