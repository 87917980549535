import { Injectable } from '@angular/core';
import {} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ConfigMiniSidebar {
    Pattern: boolean;
    FullScreen: boolean;
    Language: boolean;
    Notification: boolean;
    User: boolean;
    Sidebar: boolean;
    Signout: boolean;
    constructor() { }
}
