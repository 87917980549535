import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class GlobalVarService {

	static PROJ_NAME:string = 'VIPAY_ADMIN_';

	static KEY_USER_INFO: string = GlobalVarService.PROJ_NAME + 'user_info';
	static KEY_COM_INFO: string = GlobalVarService.PROJ_NAME + "com_info";
	static KEY_ALL_LANGS: string = GlobalVarService.PROJ_NAME + "available_langs";
	static KEY_ACTIVE_LANG: string = GlobalVarService.PROJ_NAME + "active_lang";

	static IMG_PATH:string = '/assets/gallery/';
	// static UPLOAD_IMG_URL:string = 'http://rds-testing.com/upload-image.min.php';
	static UPLOAD_IMG_URL:string = '/assets/php/upload-image.min.php'; 


	//default value
	static defaultLang: any = {
		is_default: 1,
		lang_code: 'en_US',
		name: "English"
	}

	static mapMediaRoute: any = {
		'home': {
			"article": '/articles/list',
			'portfolio': '/portfolios/list'
		},
		'detail': {
			"article": '/articles/e',
			'portfolio': '/portfolios/e'
		}
	};

	static routers: any = {
		// Sample Route
		// "component_name": {
		//   'page_name': {
		//     'record_type': 'link to page'
		//   } 
		// }
		"post": {
			'home': {
				'default': '/posts/list',
				'news': '/news/list',
				'promos': '/promos/list',
				'events': '/events/list',
				'career': '/career/list',
				'faq': '/faq/list'
			},
			'detail': {
				'default': '/posts/e',
				'news': '/news/e',
				'promos': '/promos/e',
				'events': '/events/e',
				'career': '/career/e',
				'faq': '/faq/e'
			}
		},
		"media": {
			'home': {
				"article": '/articles/list',
				'portfolio': '/portfolios/list'
			},
			'detail': {
				"article": '/articles/e',
				'portfolio': '/portfolios/e'
			}
		},
		"category": {
			'home': {
				"article": '/articles/category/list',
				'portfolio': '/portfolios/category/list',
				'faq': '/faq/category/list'
			},
			'detail': {
				"article": '/articles/category/e',
				'portfolio': '/portfolios/category/e',
				'faq': '/faq/category/e'
			}
		},
		"person" : {
			'home' : {
				'partner' : '/partners/list',
				'friend' : '/our-friends/list',
				'person' : '/person/list',
			},
			'detail' : {
				'partner' : '/partners/e',
				'friend' : '/our-friends/e',
				'person' : '/person/e',
			}
		}
	};

	static langLabelMapping: any = {
		'en_US': "English",
		'km_KH': "Khmer",
		'zh_CN': "Chinese"
	};
	constructor() { }
}
