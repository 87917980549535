import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from "app/shared/auth/auth.service";
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor{

  constructor(private authService: AuthService,
              private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
    return next.handle(request).pipe(
      catchError(err => {
        console.log(">>>>>>>Error", err);
        if(err.status === 401){
          //auto logout if 401 response returned from api

          if(this.authService.isAuthenticated()){
            this.authService.logout();
          }
          
          // location.reload(true);
          
          this.router.navigate(['/login']);
        }

        if((err.status === 0 || err.status >= 500) && err.name == "HttpErrorResponse"){
          this.router.navigate(['error', err.status]);
        }

        const error = err.error || err;
        return throwError(error);
      })
    );
  }
}
