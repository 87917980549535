import { RouteInfo } from './sidebar.metadata'; 

console.log("sidebar working");
// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] =
    [
        // {
        //     path: '/home', title: "Home", icon:'ft-home', class: '', badge: '', badgeClass:'', hide: false, isExternalLink: false, submenu: []
        // },
        {
            path: '/news/list', title: 'News', icon: 'ft-book', class: '', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[]
        },
        {
            path: '/events/list', title: 'Events', icon: 'ft-calendar', class: '', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[]
        },
        {
            path: '/promos/list', title: 'Promotions', icon: 'icon-volume-2', class: '', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[]
        },
        {
            path: '/partners', title: 'Our Partners', icon: 'icon-users', class: '', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[]
        },
        {
            path: '/our-friends', title: 'Our Friends', icon: 'icon-users', class: '', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[]
        },
        // {
        //     path: '/persons', title: 'Person', icon: 'ft-users', class: '', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[]
        // },
        {
            path: '/career/list', title: 'Careers', icon: 'ft-briefcase', class: '', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[]
        },
        // {
        //     path: '/testimonials', title: 'Testimonials', icon: 'ft-layers', class: '', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[]
        // },
        
        {
            path: '', title: 'FAQ', icon: 'icon-bubbles', class: 'has-sub', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[
                { path: '/faq/list', title: 'All FAQs', icon: '', class: '', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[]},
                { path: '/faq/category/list', title: 'FAQ Categories', icon: '', class: '', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[]},
            ]
        },
        
        // {
        //     path: '', title: 'Articles', icon: 'ft-book', class: 'has-sub', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[
        //         { path: '/articles/list', title: 'All Articles', icon: '', class: '', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[]},
        //         { path: '/articles/category/list', title: 'Articles Categories', icon: '', class: '', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[]},
        //     ]
        // },
        // {
        //     path: '', title: 'Posts', icon: 'ft-book', class: 'has-sub', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[
        //         { path: '/posts/list', title: 'All Posts', icon: '', class: '', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[]},
        //         { path: '/posts/category/list', title: 'Post Categories', icon: '', class: '', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[]},
        //     ]
        // }, 
        // {
        //     path: '', title: 'Portfolios', icon: 'ft-briefcase', class: 'has-sub', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[
        //         { path: '/portfolios/list', title: 'All Portfolios', icon: '', class: '', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[]},
        //         { path: '/portfolios/category/list', title: 'Portfolio Categories', icon: '', class: '', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[]}
        //     ]
        // },
        
        
        {
            path: '', title: 'Website Setting', icon: 'ft-briefcase', class: 'has-sub', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[
                { path: '/website-setting/list', title: 'Pages', icon: '', class: '', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[]},
                // { path: '/site menus', title: 'Site Menus', icon: '', class: '', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[]}
            ]
        },
        {
            path: '/users/list', title: 'Users', icon: 'ft-user', class: '', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[]
        },
        // {
        //     path: '', title: 'Users', icon: 'ft-user', class: 'has-sub', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[
        //         { path: '/users/list', title: 'All Users', icon: '', class: '', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[]},
        //         { path: '/users/roles/list', title: 'User Roles', icon: '', class: '', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[]}
        //     ]
        // },
        {
            path: '/company', title: 'Company', icon: 'icon-home', class: '', badge: '', badgeClass: '', hide: false, isExternalLink: false, submenu:[]
        },
        
        
    ];
