import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [ 
  // {
  //   path: 'home',
  //   loadChildren: 'app/pages/home-dashboard/home-dashboard.module#HomeDashboardModule'
  // },
  {
    path: 'company',
    loadChildren: "app/pages/company/company.module#CompanyModule"
  },
  {
    path: 'users',
    loadChildren: "app/pages/users/users.module#UsersModule"
  },
  {
    path: 'users/roles',
    loadChildren: "app/pages/users/users.module#UsersModule"
  },
  {
    path: 'persons',
    loadChildren: "app/pages/person/person.module#PersonModule",
    data:{
      recordtype:'person'
    }
  },
  {
    path:'articles',
    loadChildren:"app/pages/media/media.module#MediaModule",
    data:{
      recordtype:'article'
    }
  },
  {
    path:'posts',
    loadChildren:"app/pages/posts/posts.module#PostsModule",
    data:{
      // recordtype:'using-vipay'
    }
  },
  {
    path:'faq',
    loadChildren:"app/pages/posts/posts.module#PostsModule",
    data:{
      recordtype:'faq'
    }
  },
  {
    path:'news',
    loadChildren:"app/pages/posts/posts.module#PostsModule",
    data:{
      recordtype:'news'
    }
  },
  {
    path:'career',
    loadChildren:"app/pages/posts/posts.module#PostsModule",
    data:{
      recordtype:'career'
    }
  },
  
  {
    path:'promos',
    loadChildren:"app/pages/posts/posts.module#PostsModule",
    data:{
      recordtype:'promos'
    }
  },
  {
    path:'events',
    loadChildren:"app/pages/posts/posts.module#PostsModule",
    data:{
      recordtype:'events'
    }
  },
  
  {
    path:'portfolios',
    loadChildren:"app/pages/media/media.module#MediaModule",
    data:{
      recordtype: 'portfolio'
    }
  },
  {
    path: 'testimonials',
    loadChildren: "app/pages/person/person.module#PersonModule",
    data:{
      recordtype: 'testimonials'
    }
  },
  {
    path: 'partners',
    loadChildren: "app/pages/person/person.module#PersonModule",
    data:{
      recordtype: 'partner'
    }
  },
  {
    path: 'our-friends',
    loadChildren: "app/pages/person/person.module#PersonModule",
    data:{
      recordtype: 'friend'
    }
  },
  {
    path:'website-setting',
    loadChildren:"app/pages/website-settings/website-settings.module#WebsiteSettingsModule",
    data:{
      recordtype: 'portfolio'
    }
  }
];