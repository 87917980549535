/**
 * All configuration will setup in this class
 * @author REDFOX Web Solutions
 * @createdDate 2019-10-01
 * @changelog
 * - Sopha Pum | 2019-10-06 : add more config
 */

import { Injectable } from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";
import { TemplateConfig } from "../template-config/config.interface";
import { LocalstorageService } from './localstorage.service';
import { GlobalVarService } from './global-var.service'; 

@Injectable({
	providedIn: "root"
})
export class ConfigService {
	public templateConf: TemplateConfig;

	constructor(private _localStorage: LocalstorageService) {
		this.setConfigValue();
	}

	setConfigValue() {
		this.templateConf = {
			layout: {
				variant: "Light", // options:  Dark, Light & Transparent
				dir: "ltr", //Options: ltr, rtl
				customizer: {
					hidden: true, //options: true, false
					off: true//options: true, false - to turn off (remove customizer)
				},
				sidebar: {
					collapsed: false, //options: true, false
					showDefaultNav: false,
					size: "sidebar-md", // Options: 'sidebar-lg', 'sidebar-md', 'sidebar-sm'
					backgroundColor: "white", // Options: 'black', 'pomegranate', 'king-yna', 'ibiza-sunset', 'flickr', 'purple-bliss', 'man-of-steel', 'purple-love'

					/* If you want transparent layout add any of the following mentioned classes to backgroundColor of sidebar option :
					  bg-glass-1, bg-glass-2, bg-glass-3, bg-glass-4, bg-hibiscus, bg-purple-pizzaz, bg-blue-lagoon, bg-electric-viloet, bg-protage, bg-tundora
					*/
					backgroundImage: false, // Options: true, false | Set true to show background image
					backgroundImageURL: "assets/img/sidebar-bg/01.jpg",

				}
			}
		};
	}

	initSetting(){
		//setup active language
		let activeLang:any = this._localStorage.getItemFromLS(GlobalVarService.KEY_ACTIVE_LANG);

		console.log("active lang", activeLang);
		//if there are no recent active language in storage, we will setup for it
		if(!activeLang || !activeLang.name){
			let lstLangs:any = this._localStorage.getItemFromLS(GlobalVarService.KEY_ALL_LANGS);
			if(lstLangs && lstLangs.length){
				lstLangs.forEach(item => {
					console.log("item", item);
					if(item.is_default){
						console.log("item", item);
						this._localStorage.setItem2LS(GlobalVarService.KEY_ACTIVE_LANG, item);
					}
				});
			}
		}

		
	}
}
