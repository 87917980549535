import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ConfigMiniSidebar } from '../../shared/sidebar/mini-sidebar/config-mini-Sidebar.service'

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService, private config: ConfigMiniSidebar) {
    console.log('service guard contructor');
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    console.log('service guard canActivate', this.authService.authUser);

    if (this.authService.isAuthenticated()) {
      //set value true or false to mini Sidebar
      this.config.FullScreen = false;
      this.config.Pattern  = false;;
      this.config.Language = false;
      this.config.Notification = false;
      this.config.User    = true;
      this.config.Sidebar = true;
      return true;
    } else {
      return this.router.navigate(['/login']);
    }

    // this.router.navigate(['/pages/login'], { queryParams: { returnUrl: state.url }});
    return false;
    // return this.authService.isAuthenticated();
  }
}
