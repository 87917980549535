import { Injectable } from '@angular/core';
import { RestApiService } from '../rest-api.service';
import { GlobalVarService } from '../global-var.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends RestApiService{
  
  URL_PATH:string = "/users";
   
  
  login(username, password){
    let credential:any = {
      username: username,
      password: password
    }; 
    // return {"id": "001",
    //         "api_token": "1234567890ertyuiodfghjbvfgfahvbdnsf"};

    return this.postByPath("/login", credential); 
  }

  logout(){
    //logout user from DB
    this.postByPath("/logout", {}).subscribe(res => {


        //clear all related local storage
        this.$localStorage.clear();

        //redirect user to home page
        this.router.navigate(['/login']);
    });
    //TODO: Clear all local storage data, except config data
    //TODO: redirect to home page demo
  }

}
