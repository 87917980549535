import { Injectable } from '@angular/core';
import { GlobalVarService } from './global-var.service';

@Injectable({
	providedIn: 'root'
})
export class LocalstorageService {

	/**
	 * Method to set local storage value
	 * @param key String key
	 * @param value any object
	 */
	setItem2LS(key: string, value: any) {
		let strVal = JSON.stringify(value);

		//TODO encrypt value before add to local storage
		let encryptedVal = strVal;
		localStorage.setItem(key, encryptedVal);
	}

	/**
	 * Method to get data from local storage
	 * @param key string key to get data from local storage
	 * @return any object
	 */
	getItemFromLS(key: string) {
		let strVal: string = localStorage.getItem(key);
		let decryptedVal: any = {};
		if (strVal) {
			decryptedVal = JSON.parse(strVal);
			//TODO: decrypted value before return
		}
		return decryptedVal;
	}

	clear(){
		//clear all related local storage
		localStorage.removeItem(GlobalVarService.KEY_COM_INFO);
		localStorage.removeItem(GlobalVarService.KEY_USER_INFO);
		localStorage.removeItem(GlobalVarService.KEY_ALL_LANGS);
	}

}
