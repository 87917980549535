import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
//COMPONENTS
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { CustomizerComponent } from './customizer/customizer.component';
import { NotificationSidebarComponent } from './notification-sidebar/notification-sidebar.component';

//DIRECTIVES
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import { SidebarDirective } from './directives/sidebar.directive';
import { SidebarLinkDirective } from './directives/sidebarlink.directive';
import { SidebarListDirective } from './directives/sidebarlist.directive';
import { SidebarAnchorToggleDirective } from './directives/sidebaranchortoggle.directive';
import { SidebarToggleDirective } from './directives/sidebartoggle.directive';
import { MiniSidebarComponent } from './sidebar/mini-sidebar/mini-sidebar.component';


import { SectionHeaderComponent } from './_components/section-header/section-header.component';
import { OutputFieldComponent } from './_components/output-field/output-field.component';
import { OutputInvalidFormComponent } from './_components/output-invalid-form/output-invalid-form.component';
import { RdsInputFieldComponent } from './_components/rds-input-field/rds-input-field.component';
import { RdsButtonLoadingComponent } from './_components/rds-button-loading/rds-button-loading.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        SectionHeaderComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        NgbModule,
        TranslateModule,
        OutputFieldComponent,
        RdsInputFieldComponent,
        OutputInvalidFormComponent,
        MiniSidebarComponent,
        RdsButtonLoadingComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        TranslateModule,
        PerfectScrollbarModule, 
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        SidebarLinkDirective,
        SidebarListDirective,
        SidebarAnchorToggleDirective,
        SidebarToggleDirective,
        MiniSidebarComponent,
        SectionHeaderComponent,
        OutputFieldComponent,
        OutputInvalidFormComponent,
        RdsInputFieldComponent,
        RdsButtonLoadingComponent
        
    ]
})
export class SharedModule { }
