import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'rds:outputInvalidForm',
  templateUrl: './output-invalid-form.component.html',
  styleUrls: ['./output-invalid-form.component.scss']
})
export class OutputInvalidFormComponent implements OnInit {

  @Input() title:string;
  @Input() description:string;

  constructor() { }

  ngOnInit() {}

}
