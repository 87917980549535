import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'; 

@Component({
  selector: 'rds:inputField',
  templateUrl: './rds-input-field.component.html',
  styleUrls: ['./rds-input-field.component.scss']
})
export class RdsInputFieldComponent implements OnInit {

  bindingValue:any;

  @Input() label:string;

  @Input() value:any;
  @Output() valueChange = new EventEmitter<any>();

  constructor() { } 

  ngOnInit() {
  }

}
